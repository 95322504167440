var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-block__post-featured",class:{ 'ml-4 mr-4 mt-3': _vm.$voicer.isMobile }},[(_vm.isHomeActive)?_c('v-container',{staticClass:"pa-0"},[_c('v-sheet',{ref:"sheet",staticClass:"pa-2",class:{
        'v-sheet__desktop': _vm.$voicer.isDesktopOrTablet,
        'ma-3 mb-4': _vm.$voicer.isDesktopOrTablet,
      },attrs:{"color":"#ecf3dd"}},[_c('div',{staticClass:"text-alternate",class:{
          'body-2 font-weight-normal': _vm.$voicer.isDesktopOrTablet,
          'body-2': _vm.$voicer.isMobile,
        }},[_c('div',{staticClass:"pl-2 pr-2 pt-1"},[_vm._v("\n          Maxi Zoo vos offre les meilleures playlists conçues par des\n          vétérinaires pour détendre vos animaux\n        ")]),_vm._v(" "),_c('v-btn',{ref:"btn",staticClass:"ma-2 white--text",attrs:{"color":"#FFB300","rounded":"","depressed":""},on:{"click":function($event){return _vm.handleOpen('https://sdz.sh/cGXPv7')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#ffffff"}},[_vm._v(" mdi-rabbit ")]),_vm._v("\n          Tout doux lapinou\n        ")],1),_vm._v(" "),_c('v-btn',{ref:"btn",staticClass:"ma-2 white--text",attrs:{"color":"#819F17","rounded":"","depressed":""},on:{"click":function($event){return _vm.handleOpen('https://sdz.sh/Cz1neF')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#ffffff"}},[_vm._v(" mdi-dog ")]),_vm._v("\n          Tout doux toutou\n        ")],1),_vm._v(" "),_c('v-btn',{ref:"btn",staticClass:"ma-2 white--text",attrs:{"color":"#ED6E3C","rounded":"","depressed":""},on:{"click":function($event){return _vm.handleOpen('https://sdz.sh/7bzKZe')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#ffffff"}},[_vm._v(" mdi-cat ")]),_vm._v("\n          Tout doux matou\n        ")],1)],1)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }