var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-block__pre-heading",class:{ 'ml-4 mr-4 mt-3': _vm.$voicer.isMobile }},[(_vm.isHomeActive)?_c('v-container',{staticClass:"pa-0"},[_c('v-sheet',{ref:"sheet",staticClass:"pa-2",class:{
        'v-sheet__desktop': _vm.$voicer.isDesktopOrTablet,
        'ma-3 mb-4': _vm.$voicer.isDesktopOrTablet,
      },attrs:{"color":"primary"}},[_c('div',{staticClass:"text-alternate",class:{
          'body-2 font-weight-normal': _vm.$voicer.isDesktopOrTablet,
          'body-2': _vm.$voicer.isMobile,
        }},[_c('div',{staticClass:"pl-2 pr-2 pt-1 white--text"},[_vm._v("\n          Bienvenue sur le site de votre podcast interne !\n          "),_c('br'),_vm._v("\n          Rendez-vous une fois par mois sur Happy Radio le Mag, votre media\n          100% dédié aux équipes Maxi Zoo\n        ")])])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }